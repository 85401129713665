import { DataModel } from '@smarttask-common/src/lib/models/DataModel';
import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Globals } from '@smarttask-common/src/lib/services/globals';
import { OrganizationUserModel } from '@smarttask-common/src/lib/models/OrganizationUser/OrganizationUserModel';
import { OrganizationUserlistStoreService } from '@smarttask-app/src/app/services/OrganizationUser/store/organizationUserlistStore.service';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(
        private globals: Globals,
        private http: HttpClient,
        private organizationUserlistStoreService: OrganizationUserlistStoreService
    ) {}

    getCurrentUser = () => {
        return this.http
            .get(`${this.globals.api_base_url}${this.globals.api_version}/user/current-user`)
            .toPromise()
            .then((user: OrganizationUserModel) => {
                this.organizationUserlistStoreService.upsertOne(user);
                return user;
            })
    };

    updateName = (name: string) => {
        //Refresh all references of the current user
        var changes: Partial<OrganizationUserModel> = {
            full_name: name,
        };
        this.organizationUserlistStoreService.updateOne(this.globals.auth_data.user_id, changes);
        return this.http
            .post(`${this.globals.api_base_url}${this.globals.api_version}/user/update-name`, { full_name: name })
            .toPromise()
            .then(
                () => {
                    return;
                },
                (response: HttpErrorResponse) => {
                    return Promise.reject(response);
                }
            );
    };

    updateAvatar = (model: DataModel) => {
        return this.http
            .post(`${this.globals.api_base_url}${this.globals.api_version}/user/update-avatar`, model, {
                responseType: 'text',
            })
            .toPromise()
            .then(
                (avatar_url: string) => {
                    //Refresh all references of the current user
                    var changes: Partial<OrganizationUserModel> = {
                        avatar_url: avatar_url,
                    };
                    this.organizationUserlistStoreService.updateOne(this.globals.auth_data.user_id, changes);
                    return avatar_url;
                }
            );
    };

    updateTimezone = (timezone_in_mins: number) =>{
        this.organizationUserlistStoreService.updateOne(this.globals.auth_data.user_id, {timezone_in_mins: timezone_in_mins});

        return this.http
            .get(`${this.globals.api_base_url}${this.globals.api_version}/user/update-timezone/${timezone_in_mins}`)
            .toPromise()
            .then(
                () => {
                    return;
                }
            );
    }

    getSessions = () => {
        return this.http
            .get(`${this.globals.api_base_url}${this.globals.api_version}/user/get-sessions`)
            .toPromise()
            .then(
                (count: number) => {
                    return count;
                }
            );
    };
}
